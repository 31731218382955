import React from 'react'
import Home from '../components/HomeButton'

function Page10() {
  return (
    <div style={{ margin:'50px'}}> <div  className="container">
      <Home/>
    <div className="row justify-content-center align-items-center">
      <div style={{ backgroundColor:'#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)'}} className="col-md-9 p-4 mx-auto text-center ">
        


        <h4> Career at SJMC </h4>
        <p>The page you are looking for is not available for now. Please visit again.</p>
        <div class="d-grid gap-2 col-6 mx-auto">
  <a href="/" class="btn btn-primary" role="button">Go to Home</a>
</div>








        
      </div>
    </div>
  </div></div>
  )
}

export default Page10