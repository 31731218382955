import { width } from '@fortawesome/free-solid-svg-icons/fa0'
import React from 'react'
import Home from '../components/HomeButton'
import ma_pdf from '../asset/ma_syllabus.pdf'

function Page1() {
  return (
    <>
    <Home/>
    <div> <div  className="container">
    <div className="row justify-content-center align-items-center">
      
      <div style={{ backgroundColor:'#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)'}} className="col-md-9 p-4 mx-auto text-center ">
        <div>

    <h2 style={{ textAlign: 'center', borderBottom: '1px solid black', color: 'black', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
            <a href="#" style={{ color: 'black', textDecoration: 'none' }}>M.A. in Journalism and Mass communication</a>
        </h2>
            <h4 style={{ textAlign: 'center' }}>Course Details</h4>
            <div className=""
                style={{width: '100%', boxSizing: 'border-box', position: 'relative', minHeight: '1px', paddingLeft: '15px', paddingRight: '40px', float: '' , color: 'rgb(51, 51, 51)', fontFamily: 'Mulish', fontSize: '14px', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'center', textIndent: 0, textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: 0, WebkitTextStrokeWidth: 0, textDecorationThickness: 'initial', textDecorationStyle: 'initial' , textDecorationColor: 'initial' ,display: 'flex' , justifyContent: 'center' , flexDirection: 'column', alignItems: 'center' }}>
                <div className=""
                    style={{ boxSizing: 'border-box' ,  }}>
                    <div className="row"
                        style={{ boxSizing: 'border-box', marginLeft: '-15px', marginRight: '-15px' }}>
                        <div className="col-sm-12"
                            style={{ boxSizing: 'border-box', position: 'relative', minHeight: '1px', paddingLeft: '15px', paddingRight: '15px', float: 'left', width: '852.5px', lineHeight: 2, textAlign: 'left', fontSize: '16px' }}>
                            <p className="text-justify"
                                style={{ boxSizing: 'border-box', margin: '0px 0px 10px', fontFamily: 'Mulish, sans-serif', fontSize: '16px', lineHeight: '24px', color: 'rgb(0, 0, 0)', textAlign: 'justify' }}>
                                The School of Journalism and Mass Communication (SJMC) shall offer 2 years
                                duration i.e. 4 Semesters course and shall be designated as Master of Arts in
                                Journalism and Mass communication, abbreviated as MJMC. The syllabus comprises
                                14 Core Courses (CC) and one Elective Course (EC); one Generic Elective (GE) or
                                Discipline Specific Elective Course (DSE), one Ability Enhancement Course (AEC)
                                and two Ability Enhancement Compulsory Courses (AECC) in two years. The student
                                will be evaluated through end-semester examinations/project evaluation and the
                                teaching will be structured accordingly
                            </p>
                        </div>
                    </div>
                </div>
                <h5 style={{ boxSizing: 'border-box', fontFamily: 'Mulish, sans-serif', fontWeight: 500, lineHeight: 1.1, color: 'inherit', marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>
                    Structure of the 2 yrs (Four Semesters) Post Graduate Degree course under CBCS:
                </h5>
                <table className="table table-bordered" 
               style={{ boxSizing: 'border-box', borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: 'transparent', width: '822.5px', maxWidth: '100%', marginBottom: '20px', border: '1px solid rgb(221, 221, 221)' }} 
               width="100%">
            <thead className="font-weight-bold bg-light" 
                   style={{ boxSizing: 'border-box', fontWeight: 'bold' }}>
                <tr style={{ boxSizing: 'border-box' }}>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        Semester
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        No of Course/Papers
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        Credit per Course/ Paper
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        Total Credit
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        Minimum No. of Learning Hours #
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        No. of Core Course/ paper
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        No. of Elective Course/ paper
                    </th>
                    <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                        Code of Nature of Elective Course/ Paper
                    </th>
                </tr>
            </thead>
            <tbody style={{ boxSizing: 'border-box' }}>
                <tr style={{ boxSizing: 'border-box' }}>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        I
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        05
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        05
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        25
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        250
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        4
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        1
                    </td>
                    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                        AECC-1
                    </td>
                </tr>
                         

                <tr style={{ boxSizing: 'border-box', backgroundColor: '#f8f9fa' }}>
    <td colSpan="8" style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>SEMESTER BREAK</td>
</tr>
<tr style={{ boxSizing: 'border-box' }}>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>II</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>06</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>05</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>30</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>300</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>5</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>1</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>AECC-2</td>
</tr>

<tr style={{ boxSizing: 'border-box', backgroundColor: '#f8f9fa' }}>
    <td colSpan="8" style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>SEMESTER BREAK</td>
</tr>
<tr style={{ boxSizing: 'border-box' }}>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>III</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>06</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>05</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>30</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>300</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>5</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>1</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>AECC-2</td>
</tr>

<tr style={{ boxSizing: 'border-box', backgroundColor: '#f8f9fa' }}>
    <td colSpan="8" style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>SEMESTER BREAK</td>
</tr>

<tr style={{ boxSizing: 'border-box' }}>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>IV</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>02</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>05/10</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>15</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>150</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>0</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>2</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>EC-1* DSE-1 Or GE-1</td>
</tr>



<tr style={{ boxSizing: 'border-box' }}>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>Total</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>19</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}> </td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>100</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>1000</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>14</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>5</td>
    <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}> </td>
</tr>
                
        </tbody>
    </table>
    <h5 style={{ boxSizing: 'border-box', fontFamily: 'Mulish, sans-serif', fontWeight: '500', lineHeight: '1.1', color: 'inherit', marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>
                Master of Arts in Journalism and Mass Communication (M. J.M.C.) SEMESTER-1
            </h5>
            <table className="table table-bordered" 
                   style={{ boxSizing: 'border-box', borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: 'transparent', width: '822.5px', maxWidth: '100%', marginBottom: '20px', border: '1px solid rgb(221, 221, 221)' }}>
                <thead className="font-weight-bold" 
                       style={{ boxSizing: 'border-box', fontWeight: 'bold' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Code
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Course Component
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Name of the course
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Credit
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            C.I.A.
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            End semester exam
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Total Marks
                        </th>
                    </tr>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th colspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'center', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '1px 1px 2px', borderStyle: 'solid', borderColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Marks
                        </th>
                    </tr>
                </thead>
                <tbody style={{ boxSizing: 'border-box' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            CC-1
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Core
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Concepts and Theories of Mass Communication
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            30
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            70
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            100
                        </td>
                    </tr>

                    {/* other table rows */}
                    <tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-2</td>
    <td>Core</td>
    <td>Media – Culture and Society</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-3</td>
    <td>Core</td>
    <td>Print Media</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-4</td>
    <td>Core</td>
    <td>Photography</td>
    <td>5</td>
    <td>80</td>
    <td>20</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>AECC-1</td>
    <td>Elective</td>
    <td style={{textAlign:'left'}}>1. Bihar<br></br>
2. Theatre<br></br>
3. Creative Writing<br></br>
4. SWAYAM course(s)<br></br></td>
    <td>5</td>
    <td>50</td>
    <td>50</td>
    <td>100</td>
</tr>

                                      
                    {/* Please insert the remaining table rows similarly */}
                
                </tbody>
            </table>
            <h5 style={{ boxSizing: 'border-box', fontFamily: 'Mulish, sans-serif', fontWeight: '500', lineHeight: '1.1', color: 'inherit', marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>
                Master of Arts in Journalism and Mass Communication (M. J.M.C.) SEMESTER-2
            </h5>
            <table className="table table-bordered" 
                   style={{ boxSizing: 'border-box', borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: 'transparent', width: '822.5px', maxWidth: '100%', marginBottom: '20px', border: '1px solid rgb(221, 221, 221)' }}>
                <thead className="font-weight-bold bg-light" 
                       style={{ boxSizing: 'border-box', fontWeight: 'bold' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Code
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Course Component
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Name of the course
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Credit
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            C.I.A.
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            End semester exam
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Total Marks
                        </th>
                    </tr>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th colspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'center', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '1px 1px 2px', borderStyle: 'solid', borderColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Marks
                        </th>
                    </tr>
                </thead>
                <tbody style={{ boxSizing: 'border-box' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            CC-5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Core
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Development communication
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            30
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            70
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            100
                        </td>
                    </tr>
                    <tr style={{ boxSizing: 'border-box' }}>
    <td>CC-6</td>
    <td>Core</td>
    <td>Media Law & Ethics</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box' }}>
    <td>CC-7</td>
    <td>Core</td>
    <td>Radio Production</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box' }}>
    <td>CC-8</td>
    <td>Core</td>
    <td>Ad and PR</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box' }}>
    <td>CC-9</td>
    <td>Core</td>
    <td>Communication Research</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', margin:'20px'}}>
    <td>AEC-1</td>
    <td>Elective</td>
    <td  style={{textAlign:'left'}}>1. Music for Media <br></br>
2. Courses offered by other Centres<br></br>
3. SWAYAM course(s)<br></br></td>
    <td>5</td>
    <td>50</td>
    <td>50</td>
    <td>100</td>
</tr>
                    {/* Add more table rows as needed */}
                </tbody>
            </table>
            <h5 style={{ boxSizing: 'border-box', fontFamily: 'Mulish, sans-serif', fontWeight: '500', lineHeight: '1.1', color: 'inherit', marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>
                Master of Arts in Journalism and Mass Communication (M. J.M.C.) SEMESTER-3
            </h5>
            <table className="table table-bordered" 
                   style={{ boxSizing: 'border-box', borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: 'transparent', width: '822.5px', maxWidth: '100%', marginBottom: '20px', border: '1px solid rgb(221, 221, 221)' }}>
                <thead className="font-weight-bold bg-light" 
                       style={{ boxSizing: 'border-box', fontWeight: 'bold' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Code
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Course Component
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Name of the course
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Credit
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            C.I.A.
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            End semester exam
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Total Marks
                        </th>
                    </tr>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th colspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'center', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '1px 1px 2px', borderStyle: 'solid', borderColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Marks
                        </th>
                    </tr>
                </thead>
                <tbody style={{ boxSizing: 'border-box' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            CC-10
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Core
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Film Studies
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            30
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            70
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            100
                        </td>
                    </tr>
                    {/* Add more table rows as needed */}
                    <tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-10</td>
    <td>Core</td>
    <td>TV Production</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-12</td>
    <td>Core</td>
    <td>Research Methodology</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-13</td>
    <td>Core</td>
    <td>Media Management & Entrepreneurship</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>CC-14</td>
    <td>Core</td>
    <td>Social Media</td>
    <td>5</td>
    <td>30</td>
    <td>70</td>
    <td>100</td>
</tr>
<tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td>AEC-1</td>
    <td>Elective</td>
    <td style={{textAlign:'left'}}>1. Graphics and Animation <br></br>
2. DTP<br></br>
3. Swayam course(s)<br></br></td>
    <td>5</td>
    <td>50</td>
    <td>50</td>
    <td>100</td>
</tr>

                </tbody>
            </table><h5 style={{ boxSizing: 'border-box', fontFamily: 'Mulish, sans-serif', fontWeight: '500', lineHeight: '1.1', color: 'inherit', marginTop: '10px', marginBottom: '10px', fontSize: '14px' }}>
                Master of Arts in Journalism and Mass Communication (M. J.M.C.) SEMESTER-4
            </h5>
            <table className="table table-bordered" 
                   style={{ boxSizing: 'border-box', borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: 'transparent', width: '822.5px', maxWidth: '100%', marginBottom: '20px', border: '1px solid rgb(221, 221, 221)' }}>
                <thead className="font-weight-bold bg-light" 
                       style={{ boxSizing: 'border-box', fontWeight: 'bold' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Code
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Course Component
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Name of the course
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Credit
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            C.I.A.
                        </th>
                        <th style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            End semester exam
                        </th>
                        <th rowspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'left', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '0px 1px 2px', borderTopStyle: 'initial', borderTopColor: 'initial', borderBottomStyle: 'solid', borderBottomColor: 'rgb(221, 221, 221)', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderRightColor: 'rgb(221, 221, 221)', borderLeftColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Total Marks
                        </th>
                    </tr>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <th colspan="2" 
                            style={{ boxSizing: 'border-box', padding: '8px', textAlign: 'center', lineHeight: '1.42857', verticalAlign: 'bottom', borderWidth: '1px 1px 2px', borderStyle: 'solid', borderColor: 'rgb(221, 221, 221)', borderImage: 'initial' }}>
                            Marks
                        </th>
                    </tr>
                </thead>
                <tbody style={{ boxSizing: 'border-box' }}>
                    <tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            EC-1
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Elective
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Documentary Film Production
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            80
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            20
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            100
                        </td>
                    </tr>
                    {/* Add more table rows as needed */}
             

                    <tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td colSpan="7" style={{ textAlign: 'center' }}>OR</td>
</tr>



<tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            EC-1
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Elective
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Documentary Film Production
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            5
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            80
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            20
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            100
                        </td>
                    </tr>



                    <tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td colSpan="7" style={{ textAlign: 'center' }}>Choose Any one in two Elective papers (DSE – 1 or GE – 1)</td>
</tr>



<tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            DSE-1
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Elective
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Project On Film Production
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            10
                        </td>
                        <td colspan='2' style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Internal Evaluation
                        </td>
                        
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            200
                        </td>
                    </tr>
                    
                    <tr style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
    <td colSpan="7" style={{ textAlign: 'center' }}>OR</td>
</tr>


                    <tr style={{ boxSizing: 'border-box' }}>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            GE-1
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Elective
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            PAny Research Project 
                        </td>
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            10
                        </td>
                        <td colspan='2' style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            Internal Evaluation
                        </td>
                        
                        <td style={{ boxSizing: 'border-box', padding: '8px', lineHeight: '1.42857', verticalAlign: 'top', border: '1px solid rgb(221, 221, 221)' }}>
                            200
                        </td>
                    </tr>


                </tbody>
            </table>
            <a className="btn btn-success" 
               href={ma_pdf} 
               style={{ boxSizing: 'border-box', background: 'none rgb(15, 189, 113)', color: 'rgb(255, 255, 255)', textDecoration: 'none', display: 'inline-block', marginBottom: '0px', fontWeight: 'normal', textAlign: 'center', verticalAlign: 'middle', cursor: 'pointer', border: '1px solid rgb(13, 165, 99)', whiteSpace: 'nowrap', padding: '6px 12px', fontSize: '14px', lineHeight: '1.42857', borderRadius: '0px', userSelect: 'none' }}>
                View Course In PDF
            </a>
        </div></div></div></div></div></div>
    </>
  )
}

export default Page1